
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply bg-gray-50;

        font-family: verdana, sans-serif;
    }

    h1 {
        @apply text-4xl font-extrabold tracking-tight text-gray-700;
    }

    h2 {
        @apply text-3xl font-extrabold leading-8 tracking-tight text-gray-700;
    }

    h3 {
        @apply text-2xl font-extrabold leading-8 tracking-tight text-gray-700;
    }

    h4 {
        @apply text-xl font-extrabold leading-8 tracking-tight text-gray-700;
    }

    form {
        @apply space-y-6;
    }

    label {
        @apply block text-sm font-medium text-gray-700;
    }

    a {
        @apply underline;
    }

    .c-label--modal{
        @apply text-xs font-normal text-gray-400 uppercase;
    }

    .c-modal--input{
        @apply placeholder-gray-300 border-gray-300 rounded;
    }

    .c-input {
        @apply block w-full px-3 py-2 mt-1 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm;
    }

    .c-select__control {
        @apply border-gray-300 shadow-none rounded-md !important;
    }

    .c-select__multi-value {
        @apply text-white bg-primary !important;
    }

    .c-select__multi-value__label {
        @apply text-white !important;
    }

    .c-checkbox {
        @apply block p-2 mt-0 border border-gray-300 rounded-sm appearance-none focus:outline-none focus:ring-primary-500 focus:border-primary-500 text-primary;
    }

    .c-radio{
        @apply block p-2 mt-0 border border-gray-300 appearance-none focus:outline-none focus:ring-primary-500 focus:border-primary-500 text-primary;
    }

    .c-amendments:nth-child(odd) {
        @apply bg-gray-50
    }

    .c-amendments:nth-child(even) {
        @apply bg-gray-100
    }

    input, input[type=number] {
        @apply c-input;
    }

    .c-law-grid {
        grid-template-columns: 6fr 3fr 1fr;
        gap: 2px;
    }

    .c-laws-grid {
        grid-template-columns: 6fr 3fr;
        gap: 2px;
    }

    .c-tree-grid {
        grid-template-columns: 3fr 1fr 1fr 80px;
    }

    .c-tree-grid__buttons {
        grid-template-columns: minmax(100px, 1fr) minmax(16px,40px);
    }

    .c-grid-requirement-detail {
        grid-template-columns: 1fr max-content max-content;
    }
    .c-grid-preview__item {
        grid-template-columns: 1.75rem 1fr;
    }

    .c-grid-preview__item table {
        @apply my-2 table-auto;
    }

    .c-grid-preview__item td {
        @apply p-2 border;
    }

    .c-tree__status{
        grid-template-columns: 1rem minmax(150px,1fr );
    }

    ..c-audit-detail-info{
        height: 3rem;
    }

    .c-audit-detail-info + .c-audit-detail-info {
        border-left: 1px solid #cccccc;
        padding-left: 2rem;
    }

    .c-card {
        @apply p-4 bg-white rounded-lg shadow;
    }

    .c-table__cell{
        @apply px-4 py-2 bg-white;
    }

    .c-button {
        @apply items-center text-sm font-medium rounded-md;
    }

    @media only screen and (max-width: 992px) {
        .c-tree-grid{
            grid-template-columns: 4fr 3fr 2fr 2fr ;
            @apply gap-2;
        }
        .c-tree-grid__buttons{
            grid-template-columns: 1fr 1fr;
        }
    }

    .c-button--primary {
        @apply px-4 py-2 text-white border border-transparent shadow-sm c-button bg-primary hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500;
    }

    .c-button--primary:disabled {
        @apply cursor-default opacity-40 hover:bg-primary;
    }

    .c-button--secondary {
        @apply px-4 py-2 border border-transparent shadow-sm c-button text-primary-700 bg-primary-100 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-200;
    }

    .c-button--secondary:disabled {
        @apply cursor-default opacity-40 hover:bg-primary-100;
    }

    .c-button--outline {
        @apply px-4 py-2 text-gray-700 bg-white border border-gray-300 c-button hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500;
    }

    .c-button--outline:disabled {
        @apply cursor-default opacity-40 hover:bg-white;
    }

    .c-button--link {
        @apply text-gray-700 bg-transparent c-button focus:outline-none hover:underline;
    }

    .c-button--link:disabled {
        @apply cursor-default opacity-40;
    }

    .c-button__small {
        @apply p-2 text-xs;
    }

    .c-button__xsmall {
        @apply py-0 px-1 text-[10px];
    }

    .c-label {
        @apply text-xs font-medium text-left text-gray-500 uppercase;
    }

    .c-error {
        @apply block text-sm font-medium text-red-400;
    }

    .bg-error {
      @apply block text-sm font-medium bg-red-400 text-white;
    }

    .c-progress__step {
        @apply bg-primary hover:bg-primary-700;
    }

    .c-select *:focus {
        --tw-ring-shadow: 0 !important;
    }
}

@font-face {
    font-family: "Roboto";
    src: url('/fonts/Roboto-Bold.ttf');
    font-weight: bold;
}

@font-face {
    font-family: "Roboto";
    src: url('/fonts/Roboto-Regular.ttf');
    font-weight: normal;
}


.about_image {
    clip-path: polygon(0 0, 74% 0, 100% 37%, 100% 100%, 0% 100%);
}

@media screen and (min-width:1024px){
    .about_container::before {
        content: " ";
        background: linear-gradient(124.44deg, rgba(143, 209, 106, 0.6) -4.79%, rgba(72, 174, 180, 0) 126.4%);
        position: absolute;
        width:50vw;
        height:250px;
        z-index: -1;
        left: -30px;
        bottom: -100px;
    }

    .about_container::after {
        content: " ";
        background: #f9fafb;
        width: 200px;
        height: 100px;
        position: absolute;
        transform: rotate(45deg) translate(-81px, 140px);
        left: 30px;
        bottom: -100px;
    }
}
